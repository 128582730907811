.DatePicker .form-field {
  display: grid;
  grid-template-columns: 8fr 5fr 1fr;
  column-gap: 12px;
  margin-bottom: 12px;
}

.DatePicker .form-field input {
  font-family: 'Roboto', sans-serif;
}

.DatePicker .button {
  margin-top: 10px;
}

.DatePicker-actions {
  margin-top: 36px;
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 12px;
  row-gap: 12px;
}