.search-filter {
  background: var(--speer-base3);
  border: 1px solid var(--speer-border1);
  box-sizing: border-box;
  border-radius: 12px;
  padding: 12px 18px;
  margin-bottom: 18px;  
}

.search-filter:last-of-type {
  margin-bottom: 18px;
}

.search-filter-inner {

}

.search-filter-head {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-flow: row nowrap;
}

.search-filter-title {
  cursor: pointer;
}

.search-filter-title-fragment {
  margin-right: 3px;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 150%;
  color: var(--speer-accent-base);
}

.search-filter-title-fragment.text-muted {
  color: #73799B;
}

.search-filter-action {

}

.search-filter-body {
  margin-top: 20px;
  border-top: 1px solid var(--speer-border1);
  padding-top: 24px;
}

.search-filter-option-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 12px;
  margin-bottom: 12px;
}

.search-filter-option-row:last-of-type {
  grid-template-columns: 1fr;
  margin-bottom: 0;
}

.search-filter-option-row:last-of-type .form-field {
  margin-bottom: 12px;
}