.Accordion {
  background: var(--speer-base2);
  border: 1px solid var(--speer-border1);
  box-sizing: border-box;
  border-radius: 6px;
  margin-bottom: 18px;
}

.Accordion-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
  padding: 18px;
  cursor: pointer;
}

.Accordion-header svg {
  transition: all 200ms ease-in-out;
}

.Accordion-header svg.rotated {
  transform: rotate(180deg);
}

.Accordion-title {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  color: var(--speer-accent-base);
  margin: 0;
}

.Accordion-action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-flow: row nowrap;
}

.Accordion-action .badge {
  margin-right: 12px;
}

.Accordion-body {
  padding: 18px;
  padding-top: 0;
  margin-top: -18px;
}

.Accordion-intro {
  width: 75%;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #73799B;
  margin-bottom: 18px;
}