.AssessmentItem {
  background: var(--speer-base2);
  border: 1px solid var(--speer-border1);
  box-sizing: border-box;
  border-radius: 6px;
  margin-bottom: 24px;
  display: flex;
  flex-flow: column nowrap;
}

.AssessmentItem-info {
  padding: 12px 18px;
  border-bottom: 1px solid var(--speer-border1);
}

.AssessmentItem-info-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-flow: row nowrap;
}

.AssessmentItem-info-body {
  position: relative;
  height: calc(100% - 24px);
}

.AssessmentItem-title {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 150%;
  color: var(--speer-accent-base);
  margin: 0;
}

.AssessmentItem-number {
  color: var(--speer-text1);
}

.AssessmentItem-intro {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #73799B;
  margin: 0;
  margin-top: 12px;
  margin-bottom: 24px;
}

.AssessmentItem-actions {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
}

.AssessmentItem-actions button,
.AssessmentItem-actions .button {
  padding-left: 24px;
  padding-right: 24px;
}

.AssessmentItem-content {
  background: var(--speer-base1);
  border-radius: 6px;
}

.AssessmentItem-content-header {
  padding: 12px 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
}

.AssessmentItem-subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #73799B;
}

.AssessmentItem-content-body {
  border-top: 1px solid var(--speer-border1);
  padding: 12px 18px 0;
}

.AssessmentItem-content-body .form-field label {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #73799B;
}

.AssessmentItem-content-body .form-field input,
.AssessmentItem-content-body .form-field textarea {
  background: var(--speer-base2);
  border: 1px solid var(--speer-border1);
  box-sizing: border-box;
  border-radius: 6px;
}

.AssessmentItem-header-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-flow: row nowrap;
}

.AssessmentItem-header-actions .button,
.AssessmentItem-header-actions button {
  margin-right: 8px;
}

.AssessmentItem--header-action {
  transition: all 200ms ease-in-out;
  cursor: pointer;
}

.AssessmentItem--header-action.inverted {
  transform: rotate(180deg);
}

.AssessmentItem-content-body.form-field textarea {
  background: var(--speer-base2);
}

.AssessmentSubItems {
  padding-left: 24px;
  margin-top: -12px;
}

.AssessmentSubSubItems {
  padding-left: 24px;
  margin-top: -12px;
}