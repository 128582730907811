.Login {
  display: flex;
  flex-flow: row nowrap;
  background: linear-gradient(68.66deg, #001C75 0%, var(--speer-accent-primary) 100%);
  min-height: 100vh;
}

.Login-info {
  flex: 3;
  background: var(--speer-base1);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 0 200px;
}

.Login-info h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 130%;
  text-align: center;
  color: var(--speer-accent-base);
  margin: 0;
  margin-bottom: 48px;
}

.Login-info h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: var(--speer-accent-primary);
  margin: 0;
  margin-bottom: 12px;
}

.Login-benefits {
  margin-bottom: 48px;
}

.Login-benefit {
  background: var(--speer-base3);
  border-radius: 6px;
  padding: 18px;
  margin-bottom: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: row nowrap;
}

.benefit-icon {
  background: var(--speer-accent-primary);
  height: 36px;
  width: 36px;
  min-width: 36px;
  max-width: 36px;
  margin-right: 24px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.benefit-body {
}

.benefit-body h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  color: var(--speer-accent-base);
  margin: 0;
  margin-bottom: 8px;
}

.benefit-body p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: var(--speer-accent-base);
  margin: 0;
}

.logo-grid {
  padding-top: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 12px;
  width: 100%;
}

.logo-grid-tile {
  background: var(--speer-base3);
  border-radius: 6px;
  height: 65px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-grid-item,
.logo-grid-item-small,
.logo-grid-item-big {
  opacity: 0.5;
}

.logo-grid-item {
  height: 30px;
  width: auto;
}

.logo-grid-item-small {
  height: 18px;
  width: auto;
}

.logo-grid-item-big {
  height: 36px;
  width: auto;
}

.Login-form-fields {
  margin-top: 36px;
}

.Login-form {
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 120px;
}

.Login-form-inner {
  background: var(--speer-base2);
  border: 1px solid var(--speer-border1);
  box-sizing: border-box;
  border-radius: 12px;
  padding: 48px 36px;
  min-width: 420px;
}

.Login-form-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 36px;
}

.Login-form-logo img {
  height: 24px;
  width: auto;
}

.Login-form-intro h3 {
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: var(--speer-accent-base);
  margin: 0;
  margin-bottom: 12px;
}

.Login-form-intro h3 strong {
  font-weight: bold;
}

.Login-form-intro p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: var(--speer-text2);
  margin-bottom: 48px;
}

.form-cta {
  margin-top: 36px;
}

.form-cta p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #73799B;
  margin-bottom: 0;
}

.form-cta p a {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: var(--speer-accent-primary);  
}

/* Medium devices (landscape phones & tablets, 768px and up) */
@media screen and (max-width: 992px) {
  .Login {
    display: flex;
    flex-flow: column-reverse nowrap;
  }

  .Login-info {
    padding: 60px 24px;
  }

  .Login-form {
    padding: 36px 24px;
  }

  .Login-form-inner {
    background: var(--speer-base2);
    border: 1px solid var(--speer-border1);
    box-sizing: border-box;
    border-radius: 12px;
    padding: 24px;
  }
}