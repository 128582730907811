.Dashboard-stats {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 12px;
}

.Dashboard-stat {
  background: var(--speer-base3);
  border-radius: 6px;
  padding: 12px 18px;
}

.stat-headline {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: var(--speer-input-color);
}

.stat-value {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #25334E;
  margin: 12px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
}

.stat-value .badge {
  margin-left: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
}

.stat-value .badge svg {
  margin-left: 4px;
}

.stat-subline {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #73799B;
}

.Dashboard-missions {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr  1fr;
  column-gap: 18px;
  row-gap: 18px;
}

.Dashboard-section-header {
  margin-top: 60px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: var(--speer-accent-base);
}

.Dashboard-missions .SearchPrompt-form {
  width: 100%;
  min-width: initial;
}

.Dashboard-missions .SearchPrompt-input {
  min-width: 250px;
  margin-bottom: 0;
}

/* Medium devices (landscape phones & tablets, 768px and up) */
@media screen and (max-width: 992px) {
  .Dashboard-stats {
    grid-template-columns: 1fr 1fr;
    row-gap: 12px;
  }

  .Dashboard-missions {
    grid-template-columns: 1fr;
    column-gap: 12px;
    row-gap: 12px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media screen and (max-width: 576px) {}