.file-card-container {
  border: 1px solid var(--speer-border1);
  background: var(--speer-base3);
  border-radius: 6px;
}

.file-card {
  background: var(--speer-base3);
  border-radius: 6px;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
}

.file-card-title {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: var(--speer-text1);
}

.file-card-meta {
  margin-left: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  color: #A6A7BB;
}

.file-card-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
}

.file-card-action {
  margin-left: 12px;
}

.file-card.file-card-undelivered {
  background: var(--speer-accent-primary);
}

.file-card.file-card-undelivered .file-card-title {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: var(--speer-base1);
}

.file-card-acl {
  padding: 12px;
  display: block;
  background: var(--speer-base1);
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}