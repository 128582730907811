.tooltip-container {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
  z-index: 999999;
}

.tooltip-container svg {
  margin-left: 3px;
}

.tooltip-popup {
  position: absolute;
  top: 24px;
  left: 12px;
  padding: 4px 8px;
  border-radius: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  background: var(--speer-accent-base);
  color: var(--speer-base1);
  opacity: 0;
  transition: all 200ms ease;
  transition-delay: 200ms;
  z-index: 999999;
  box-shadow: 0px 3px 12px var(--speer-shadow);
  width: fit-content;
  text-align: left;
}

.tooltip-container:hover .tooltip-popup {
  opacity: 1;
}