.Signup {
  background: var(--speer-base3);
  height: 100%;
  width: 100%;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Signup-container {
  background: var(--speer-base1);
  border: 1px solid var(--speer-border1);
  box-sizing: border-box;
  border-radius: 12px;
  width: 440px;
  padding: 36px;
  position: relative;
  margin-top: 60px;
}

.Signup-title {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: var(--speer-accent-base);
  margin: 0;
  margin-bottom: 12px;
}

.Signup-title strong {
  font-weight: bold;
}

.Signup-intro {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: var(--speer-text2);
  margin: 0;
}

.Signup-header {
  margin-bottom: 36px;
}

.form-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
  margin-top: 48px;
}

.Signup-section {
  margin-bottom: 18px;
  border-top: 1px solid var(--speer-border1);
  margin-top: 36px;
  padding-top: 36px;
  text-align: center;
}

.Signup-section-center {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: column nowrap;
}

.Signup-section-negative-space {
  margin-top: 0;
  padding-top: 0;
}

.Signup-section-title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: var(--speer-accent-base);
  margin: 0;
  margin-bottom: 12px;
}

.Signup-section-intro {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #73799B;
  margin: 0;
  margin-bottom: 24px;
}

.Signup-section-cta,
.Signup-submit {
  margin-left: auto;
  margin-right: auto;
}

.Signup-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
}

.Signup-logo {
  width: 100px;
  margin-top: 84px;
}

.Signup-sections-horizontal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: column nowrap;
  margin-top: 36px;
}

.Signup-sections-horizontal button {
  padding: 12px 24px;
  margin-bottom: 24px;
  width: 200px;
}