table.DataTable.EventStats {
  margin-bottom: 24px;
}

table.DataTable.EventStats td:first-of-type {
  width: 24px;
}

table.DataTable.EventStats td:nth-of-type(2n) {
  line-height: 30px;
  text-align: left;
  vertical-align: initial;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 12px;
}