.Settings-grid {
  margin-top: 24px;
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr 1fr;
}

.Settings-grid-card.Settings-grid-card-primary {
  background: var(--speer-base2);
  border: 1px solid var(--speer-border1);
  box-sizing: border-box;
  border-radius: 6px;
  padding: 18px;
  grid-column-start: 1;
  grid-column-end: 3;
  padding-bottom: 24px;
}

.Settings-grid-card-body {
  max-width: 75%;
}

.Settings-fields {
  display: grid;
  grid-gap: 12px 24px;
}

.Settings-fields.Settings-fields-c1 {
  grid-template-columns: 1fr;
}

.Settings-fields.Settings-fields-c2 {
  grid-template-columns: 1fr 1fr;
}

.Settings-fields {
  grid-template-columns: 1fr 1fr 1fr;
}

.Settings-fields.Settings-fields-c4 {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.Settings-fields.Settings-fields-c6 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.Settings-fields.Settings-fields-c8 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.form-field-second-half {
  grid-column-start: 3;
  grid-column-end: 5;
}

/* MOBILE */

/* Medium devices (landscape phones & tablets, 768px and up) */
@media screen and (max-width: 992px) {
  .Settings-fields.Settings-fields-c4 {
    grid-template-columns: 1fr 1fr;
  }
  
  .Settings-fields.Settings-fields-c6 {
    grid-template-columns: 1fr 1fr 1fr;
  }
  
  .Settings-fields.Settings-fields-c8 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media screen and (max-width: 576px) {}
