.ProjectStats-section {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 12px;
  row-gap: 12px;
  margin-top: 36px;
}

.ProjectStats-card {
  background: var(--speer-base2);
  border: 1px solid var(--speer-border1);
  box-sizing: border-box;
  border-radius: 6px;
  padding: 12px 18px 18px;
}

.ProjectStat-title {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: var(--speer-input-color);
  margin: 0;
  margin-bottom: 12px;
}

.ProjectStat-value {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: var(--speer-accent-base);
  margin: 0;
}

.ProjectStats-section-title {
  margin-top: 48px;
  margin-bottom: -18px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #959ABA;
}