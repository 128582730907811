.TableControl {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
}

.TableCounter {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: var(--speer-text1);
}

/* Medium devices (landscape phones & tablets, 768px and up) */
@media screen and (max-width: 992px) {
  .SearchPrompt-panel {
    margin-right: none;
    height: 80vh !important;
  }

  .SearchPrompt-input {
    min-width: 60% !important;
  }

  .TableCounter {
    display: none;
  }

  .TableHeader {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-flow: column nowrap;
    margin-bottom: 30px;
    margin-top: 0;
  }

  .TableControl {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row nowrap;
    width: 100%;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media screen and (max-width: 576px) {}