.Makro-action-link,
.Makro-action-link:hover {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--speer-accent-primary);
  font-size: 12px;
  height: 24px;
}

.Makro-action-link--icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--speer-accent-primary);
  border-radius: 100px;
  height: 24px;
  width: 24px;
  margin-left: 24px;
}

.Makro {
  background: var(--speer-base1);
  padding: 9px 9px 9px 18px;
  border: 1px solid var(--speer-border1);
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
  transition: all 200ms ease-in;
  position: relative;
}

.Makro--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.Makro-container--up .Makro--open {
  border-radius: 9px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.Makro:hover,
.Makro.info {
  cursor: pointer;
  border: 1.5px solid var(--speer-accent-primary);
}

.Makro-label {
  color: var(--speer-text2);
  font-size: 14px;
}

.Makro-action {
  opacity: 0;
  transition: all 200ms ease-in;
}

.Makro:hover .Makro-action,
.Makro.info .Makro-action {
  opacity: 1;
}

.Makro.info {
  background: var(--speer-base3);
  border: 1px solid var(--speer-border1);
  cursor: initial;
}