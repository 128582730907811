.ProgressBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
  position: absolute;
  top: -60px;
  left: 5%;
  width: 90%;
}

.ProgressBar-step {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--speer-button-color1);
  background: var(--speer-button1);
  border: 1px solid var(--speer-button1);
  box-sizing: border-box;
  border-radius: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  z-index: 3;
}

.ProgressBar-step-active {
  color: var(--speer-base1);
  background: var(--speer-button-color1);
  border: 1px solid var(--speer-button-color1);
}

.ProgressBar-step-done {
  color: var(--speer-button-color1);
  background: #FAFAFF;
  border: 1px solid var(--speer-button-color1);
  cursor: pointer;
}

.ProgressBar::before {
  content: '';
  height: 2px;
  width: 100%;
  background: var(--speer-button1);
  position: absolute;
  z-index: 1;
}

.ProgressBar-indicator {
  height: 2px;
  width: 100%;
  background: var(--speer-button-color1);
  position: absolute;
  z-index: 2;
}

.ProgressBar-hidden-step {
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--speer-button-color1);
  position: absolute;
  right: -120px;
  background: var(--speer-base3);
  z-index: 3;
  padding: 9px;
}