.Aside {
  display: grid;
  column-gap: 24px;
  grid-template-columns: 240px auto;
  min-height: 100vh;
  width: 100vw;
}

.Sidebar {
  background: var(--speer-base2);
  border-right: 1px solid var(--speer-border1);
}

.Sidebar-inner {
  height: calc(100vh - 24px);
  position: fixed;
  max-width: 216px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column nowrap;
  padding: 12px;
  overflow: auto;
}

.Sidebar-section {
  margin-bottom: 36px;
  width: 100%;
}

.Sidebar-section.Sidebar-section--narrow {
  margin-bottom: 24px;
}

.Sidebar-section img {
  padding: 12px;
  height: 24px;
  width: auto;
}

.Sidebar-section:last-of-type {
  margin-top: auto;
  margin-bottom: 0;
}

.Sidebar-section-header {
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Sidebar-section-title {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #959ABA;
}

.Sidebar-section-action {
  cursor: pointer;
}

.Sidebar-link {
  padding: 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
  cursor: pointer;
}

.Sidebar-link:hover,
.Sidebar-link.Sidebar-link--active {
  background: var(--speer-base3);
}

.Sidebar-link.Sidebar-link--active .Sidebar-link-label {
  color: var(--speer-accent-primary);
  font-weight: bold;
}

.Sidebar-link.Sidebar-link--active .Sidebar-link-icon svg {
  stroke: var(--speer-accent-primary);
}

.Sidebar-link-icon {
  margin-right: 12px;
}

.Sidebar-link-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: var(--speer-text1);  
}

.Content {
  padding-top: 24px
}

/* MOBILE */

@media only screen and (max-width: 1200px) {
  .Aside {
    display: block;
  }

  .Sidebar {
    display: none;
  }

  .Content {
    padding-top: 72px;
    padding-left: 12px;
    padding-right: 12px
  }

  .ContentPanel {
    display: block;
  }

  .ContentPanel-sidebar {
    position: relative;
    width: 100%;
    margin-left: 24px;
    height: auto;
  }
}