.TableHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
  margin-bottom: 30px;
  margin-top: 48px;
}

.TableHeader-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: var(--speer-accent-base);
  margin: 0;
}

.TableHeader.TableHeader-top-margin {
  margin-top: 72px;
}

.TableHeader.TableHeader-no-top-margin {
  margin-top: 0;
}

.TableAction-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #8185A0;
  margin-right: 12px;
}

.TableAction-action button {
  padding: 9px 18px;
  font-size: 14px;
}