.ProfileGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
}

.ProfileCard-list {
  background: var(--speer-base1);
  margin-left: -18px;
  margin-right: -18px;
  margin-bottom: -24px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 6px 18px;
  border-top: 1px solid var(--speer-border3);
}

.ProfileCard-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px dashed var(--speer-border2);
}

.ProfileCard-list-item:last-of-type {
  border-bottom: none;
}

.ProfileCard-list-item-label {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: #757692;
}

.ProfileCard-list-item-value {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 16px;
  text-align: right;
  color: var(--speer-input-color);
}

.ProfileCard-list-item-value a {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: var(--speer-accent-primary);
}

.ProfileCard-tags {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row wrap;
  background: var(--speer-base1);
  margin-left: -18px;
  margin-right: -18px;
  margin-bottom: -24px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 18px 18px 0;
  border-top: 1px solid var(--speer-border3);
}

.ProfileCard-tag {
  color: var(--speer-button-color1);
  background: var(--speer-button1);
  border-radius: 100px;
  padding: 9px 18px;
  margin-right: 9px;
  margin-bottom: 18px;
}

/* Medium devices (landscape phones & tablets, 768px and up) */
@media screen and (max-width: 992px) {
  .ProfileGrid {
    grid-template-columns: 1fr;
  }

  .ProfileGrid .SplitCard,
  .ProfileGrid .ActionCard-double {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-template-columns: 1fr;
  }

  .ProfileGrid .SplitCard .SplitCard-right {
    border-left: none;
    border-top: 1px solid var(--speer-border3);
    border-top-right-radius: 0;
    border-bottom-left-radius: 5px;
  }

  .ProfileCard-list {
    overflow: auto;
  }

  .ProfileGrid .ActionCard {
    overflow-x: hidden;
  }

  .ProfileGrid .DataTable {
    min-width: 450px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media screen and (max-width: 576px) {}