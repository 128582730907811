.WidgetFeed {
  padding: 12px;
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  flex-flow: column nowrap;
}

.WidgetFeed .Makro-container,
.WidgetFeed > .Makro:not(.Makro--open) {
  margin-bottom: 12px;
  box-shadow: 0px 3px 9px rgba(32, 48, 82, 0.09);
  border-radius: 12px;
}

.WidgetFeed .Makro:last-of-type {
  margin-bottom: 0;
}

.WidgetFeed-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--speer-base1);
  border: 1px solid var(--speer-border1);
  stroke: var(--speer-accent-base);
  border-radius: 100px;
  height: 36px;
  width: 36px;
  transition: all 200ms ease-in;
  cursor: pointer;
  align-self: flex-end;
}

.WidgetFeed-toggle-icon {
  height: 18px;
  width: auto;
  opacity: 0.25;
  transition: all 200ms ease-in;
}

.WidgetFeed-toggle:hover,
.WidgetFeed-toggle.WidgetFeed-toggle--active {
  border: 1.5px solid var(--speer-accent-primary);
}

.WidgetFeed-toggle.WidgetFeed-toggle--active {
  background: var(--speer-accent-primary);
}

.WidgetFeed-toggle:hover .WidgetFeed-toggle-icon,
.WidgetFeed-toggle.WidgetFeed-toggle--active .WidgetFeed-toggle-icon {
  opacity: 1;
}