.Makro-menu {
  background: tomato;
  background: var(--speer-base1);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-flow: column nowrap;
  /* transition: all 200ms ease-in; */
  color: var(--speer-text1);
  font-size: 12px;
  height: 0;
  overflow: hidden;
  padding: 0 18px;
}

.Makro-container--down .Makro-menu {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.Makro-container--up .Makro-menu {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.Makro-menu.Makro-menu--open {
  padding: 12px 18px;
  height: 100%;
  max-height: 42px;
  overflow: auto;
}

.Makro-container--down .Makro-menu.Makro-menu--open {
  border-left: 1px solid var(--speer-border2);
  border-right: 1px solid var(--speer-border2);
  border-bottom: 1px solid var(--speer-border2);
}

.Makro-container--up .Makro-menu.Makro-menu--open {
  border-left: 1px solid var(--speer-border2);
  border-right: 1px solid var(--speer-border2);
  border-top: 1px solid var(--speer-border2);
}

.Makro-menu .Makro-menu-item {
  margin-bottom: 9px;
  cursor: pointer;
}

.Makro-menu .Makro-menu-item:last-of-type {
  margin-bottom: 0;
}

.Makro-menu-item:hover,
.Makro-menu-item.selected {
  transition: all 200ms ease-in;
  color: var(--speer-accent-primary);
}

.Makro-container--up {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-flow: column-reverse nowrap;
}