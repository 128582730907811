.td-badge {
  position: relative;
  padding: 4px 12px;
  border-radius: 3px;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 14px;
  margin: 0;
  border: 1px solid var(--speer-accent-primary);
  background: var(--speer-base1);
  color: var(--speer-accent-primary);
}

.td-badge.censored {
  background: var(--speer-button1);
  color: var(--speer-button-color1);
  font-weight: normal;
  border: none;
}

td.xs {
  width: 30px;
}

td.sm {
  width: 130px;
}

td.md {
  width: 150px;
}

td.xl {
  width: 250px;
}

@media screen and (max-width: 992px) {
  .DataTable th {
    min-width: initial;
  }

  .DataTable .ProfileRow td.xs {
    min-width: 40px;
  }
  
  td.sm {
    min-width: 80px;
  }
  
  td.md {
    min-width: 150px;
  }
  
  td.xl {
    min-width: 180px;
  }
}