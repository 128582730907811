.SearchPrompt-panel {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  grid-column: 1 / span 2;
  margin-right: 24px;
  height: calc(100vh - 48px);
}

.SearchPrompt-title {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: var(--speer-accent-base);
  margin: 0;
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
}

.SearchPrompt-title img {
  margin-left: 6px;
}

.SearchPrompt-subline {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: var(--speer-text2);
  margin: 0;
  margin-bottom: 36px;
}

.SearchPrompt-form {
  position: relative;
  min-width: 400px;
}

.SearchPrompt-form-inline input {
  background: var(--speer-base3);
  width: 100%;
  min-width: auto;
  margin: 0;
  margin-bottom: 6px;
}

.Widget-body .SearchPrompt-form input {
  width: calc(100% - 48px);
}

.SearchPrompt-form-inline .SearchPrompt-input-icon {
  color: #DBDFEF;
}

.SearchPrompt-form button {
  position: absolute;
  top: 7px;
  right: 6px;
}

.SearchPrompt-input {
  position: relative;
  margin-bottom: 36px;
  font-family: 'Roboto', sans-serif;
  background: var(--speer-base1);
  border: 1px solid var(--speer-border1);
  box-sizing: border-box;
  border-radius: 6px;
  box-shadow: none;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: var(--speer-input-color);
  padding: 12px 18px;
  width: 100%;
  min-width: 400px;
  padding-left: 48px;
  padding-right: 42px;
}

.SearchPrompt-input-icon {
  color: var(--speer-border1);
  position: absolute;
  top: 12px;
  left: 12px;
}