.number-input {
  position: relative;
}

.input-label {
  position: absolute;
  top: 15px;
  right: 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: right;
  color: #B4BBD9;
}