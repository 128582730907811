.Project-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
}

.Project-header-group {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-flow: row nowrap;
}

.ProjectCards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
  row-gap: 24px;
}

.ProjectCard-full {
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-flow: row nowrap;
  padding: 0;
}

.ProjectCard-info,
.ProjectCard-surface {
  flex: 1;
  padding: 18px;
  padding-bottom: 24px;
}

.ProjectCard-surface {
  background: var(--speer-base1);
  border-left: 1px solid var(--speer-border3);
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.ProjectCard-surface .file-card-container,
.SplitCard-right .file-card-container {
  margin-bottom: 12px;
}

/* Medium devices (landscape phones & tablets, 768px and up) */
@media screen and (max-width: 992px) {
  .ProjectCards {
    grid-template-columns: 1fr;
    column-gap: 24px;
    row-gap: 12px;
  }

  .ProjectCard-full {
    grid-column-end: 1;
    flex-flow: column nowrap;
  }

  .ProjectCard-surface {
    border-left: none;
    border-top: 1px solid var(--speer-border3);
    border-top-right-radius: 0;
    border-bottom-left-radius: 6px;
  }

  .Project-header .Dropdown {
    margin-top: 12px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media screen and (max-width: 576px) {}