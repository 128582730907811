/* UTIL */

.hidden {
  display: none;
}

.invisible {
  visibility: hidden;
}

p svg {
  cursor: pointer;
}

.blurred {
  opacity: 0.3;
}

.dimmed {
  opacity: 0.7;
}

svg.action {
  cursor: pointer;
  transition: all 200ms ease-in-out;
}

svg.action:hover {
  opacity: 0.8;
}

.link-style {
  font-weight: bold;
  color: var(--speer-accent-primary);
  cursor: pointer;
  transition: all 200ms ease;
}

.link-style:hover {
  color: var(--speer-input-color);
}

.margin-bottom {
  margin-bottom: 24px;
}

.margin-top {
  margin-top: 24px;
}

.margin-left {
  margin-left: 12px;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.blurred {
  opacity: 1;
  filter: blur(2px);
  -webkit-filter: blur(3px);
}

.blurred.blurred-more {
  filter: blur(4px);
  -webkit-filter: blur(4px);
}

.icon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.icon.muted {
  color: #959ABA;
  fill: #959ABA;
}

textarea {
  resize: none;
}

.load-more {
  margin-top: 36px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.line-through {
  text-decoration: line-through;
  display: inline-block;
  margin-right: 0.2em;
  opacity: 0.5;
}

.correction {
  display: inline-block;
  margin-right: 0.5em;
  font-weight: bold;
}

/* TEXT STYLES */

.warning {
  font-style: normal;
  font-weight: bold !important;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  color: tomato !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.warning svg {
  margin-right: 6px;
}

.text-muted {
  color: #959ABA;
  line-height: 130%;
}

.text-muted.placeholder-text {
  line-height: 150%;
  margin: 0;
  padding: 24px;
  text-align: center;
}

.text-muted a {
  color: var(--speer-accent-primary);
  text-decoration: none;
  font-weight: bold;
  transition: all 200ms ease-out;
}

.text-muted a.base {
  color: var(--speer-accent-base);
}

.callout {
  padding: 12px;
  background: var(--speer-base3);
  border-radius: 6px;
  margin-bottom: 36px;
}

.callout p {
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #73799B;
  margin: 0;
}

p.centered {
  text-align: center;
}

.text-success {
  color: limegreen !important;
}

.text-danger {
  color: tomato !important;
}

/* SEPARATORS */

.separator {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 150%;
  text-align: center;
  color: #959ABA;
  padding-bottom: 6px;
  border-bottom: 1px solid var(--speer-border1);
  width: 100%;
}

/* FORM FIELDS */

.form-field {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 24px;
}

.form-field.less-margin {
  margin-bottom: 12px;
}

.no-margin {
  margin-bottom: 0 !important;
}

.form-field label {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: var(--speer-input-color);
  margin: 0;
  margin-bottom: 6px;
}

.form-field input,
.form-field textarea {
  font-family: 'Roboto', sans-serif;
  background: var(--speer-base1);
  border: 1px solid var(--speer-border1);
  box-sizing: border-box;
  border-radius: 6px;
  box-shadow: none;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: var(--speer-input-color);
  padding: 12px 18px;
  width: 100%;
  margin: 0;
}

.form-field input:disabled {
  opacity: 0.5;
}

.form-field-sidenote {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #73799B;
  text-decoration: none;
  margin-top: 6px;
}

.form-field-info {
  color: #959ABA;
  line-height: 130%;
  margin-top: 6px;
  line-height: 130%;
}

/* LINKS */

a {
  color: #73799B;
  text-decoration: none;
  transition: all 200ms ease-out;
}

a:hover {
  color: var(--speer-input-color);
}

/* BUTTONS */

button,
.button {
  font-family: 'Roboto Mono', sans-serif;
  border-radius: 100px;
  padding: 12px 36px;
  box-shadow: none;
  outline: none;
  border: none;
  font-style: normal;
  font-weight: bold !important;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

button span,
.button span {
  margin-left: 8px;
}

button.primary,
.button.primary {
  background: var(--speer-accent-primary);
  color: var(--speer-base1);
}

button.primary.ghost,
.button.primary.ghost {
  background: var(--speer-base1);
  color: var(--speer-accent-primary);
  border: 1px solid var(--speer-accent-primary);
}

button.primary.ghost:hover,
.button.primary.ghost:hover {
  background: var(--speer-accent-primary);
  color: var(--speer-base1);
  border: 1px solid var(--speer-accent-primary);
}

button.primary.inverse,
.button.primary.inverse {
  background: var(--speer-base1);
  color: var(--speer-accent-primary);
}

button.primary.inverse:hover,
.button.primary.inverse:hover {
  background: none;
  color: var(--speer-base1);
}

button.secondary,
.button.secondary {
  background: var(--speer-button1);
  color: var(--speer-button-color1);
}

button.secondary:hover,
.button.secondary:hover {
  background: var(--speer-accent-primary);
  color: var(--speer-base1);
}

button.secondary:hover svg,
.button.secondary:hover svg {
  stroke: var(--speer-base1);
}

button.ghost,
.button.ghost {
  background: none;
  color: #33415E;
  border: 1px solid var(--speer-border1);
}

input.search,
button.search {
  color: #33415E;
  font-size: 14px;
  line-height: 18px;
  border: 1px solid var(--speer-border1);
  box-sizing: border-box;
  border-radius: 100px;
  background: none;
  padding: 9px 18px;
  min-width: 225px;
  text-align: left;
  justify-content: flex-start;
  outline: none !important;
}

button.danger,
.button.danger {
  background: #FDEEEB;
  color: #982D2F;
}

button.danger:hover,
.button.danger:hover {
  background: tomato;
  color: var(--speer-base1);
}

button.danger:hover svg,
.button.danger:hover svg {
  stroke: var(--speer-base1);
}

.full-width,
button.full-width,
.button.full-width {
  width: 100%;
}

button.circle,
.button.circle {
  padding: 0;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

button.circle.small,
.button.circle.small {
  height: 24px;
  width: 24px;
  min-height: 24px;
  min-width: 24px;
}

button.option,
.button.option {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  color: #A6A7BB;
  background: var(--speer-base2);
  border: 1px solid var(--speer-border2);
  box-sizing: border-box;
  border-radius: 24px;
  padding: 6px 12px;
}

button.option:hover,
.button.option:hover {
  background: var(--speer-accent-primary);
  color: var(--speer-base1);
  border: 1px solid var(--speer-accent-primary);
  font-weight: 600;
}

button.link,
.button.link {
  background: none;
  border: none;
  color: #B4BBD9;
  padding: 0;
}

button.link:hover,
.button.link:hover {
  color: var(--speer-accent-primary);
}

button.small,
.button.small {
  font-size: 14px;
  line-height: 16px;
}

button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* button.disabled:hover {
  background: var(--speer-button1) !important;
  color: var(--speer-button-color1) !important;
} */

/* PANEL HEADERS */

.PanelHeader {
  margin-bottom: 24px;
  position: relative;
}

.PanelHeader-title {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: var(--speer-accent-base);
  margin: 0;
  margin-bottom: 6px;
}

.PanelHeader-title strong {
  font-weight: bold;
}

.PanelHeader-subline {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #717799;
  margin: 0;
}

.PanelHeader-action {
  position: absolute;
  top: 0;
  right: 0;
}

/* MAIN LAYOUT */

.ContentPanel {
  padding-right: 24px;
  padding-bottom: 24px;
}

.ContentPanel-sidebar {
  position: fixed;
  z-index: 999999;
  width: 376px;
  bottom: 0;
  right: 0;
}

.ContentPanel-sidebar-overflow {
  overflow-y: auto;
  top: 0;
  right: 0;
  padding: 24px;
  height: calc(100% - 24px);
}

.ContentPanel-sidebar-overflow .Widget,
.ContentPanel-sidebar-overflow .Widget-body {
  overflow-y: visible;
  max-height: none;
}

/* DATA TABLES */

table.DataTable {
  width: 100%;
  border-collapse: collapse;
}

table.DataTable.DataTable-bm {
  margin-bottom: 60px;
}

table.DataTable thead {
  padding-bottom: 12px;
}

table.DataTable th {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #757692;
  text-align: left;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--speer-border2);
}

table.DataTable.th-right th {
  text-align: right;
}

table.DataTable th:last-of-type,
table.DataTable td:last-of-type {
  text-align: right;
}

table.DataTable.admin-actions th,
table.DataTable.admin-actions td {
  text-align: right;
}

table.DataTable.admin-actions th:first-of-type,
table.DataTable.admin-actions td:first-of-type {
  text-align: left;
}

table.DataTable.DataTable-left-aligned th:last-of-type,
table.DataTable.DataTable-left-aligned td:last-of-type {
  text-align: left;
}

table.DataTable td {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: var(--speer-text3);
  text-align: left;
  padding-top: 12px;
  padding-bottom: 12px;
  position: relative;
  z-index: 2;
}

table.DataTable td.flex-cell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
}

table.DataTable td.flex-cell img {
  margin-right: 6px;
}

table.DataTable td.flex-cell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
}

table.DataTable a {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: var(--speer-accent-primary);
}

table.DataTable td.placeholder {
  text-align: center;
}

table.DataTable td.placeholder p {
  margin: 0;
}

table.DataTable tr.highlight {
  background: var(--speer-base3);
  position: relative;
}

table.DataTable tr.highlight td:first-of-type:after {
  content: '';
  height: 100%;
  width: 12px;
  background: var(--speer-base3);
  position: absolute;
  top: 0;
  left: -12px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

table.DataTable tr.highlight td:last-of-type:after {
  content: '';
  height: 100%;
  width: 12px;
  background: var(--speer-base3);
  position: absolute;
  top: 0;
  right: -12px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

table.DataTable tfoot {
  padding-top: 12px;
}

table.DataTable tfoot td {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #757692;
  text-align: left;
  padding-top: 12px;
  border-top: 1px solid var(--speer-border2);
}

table.DataTable .form-field {
  margin-bottom: 0;
}

/* BADGES */

.badge {
  position: relative;
  padding: 4px 12px;
  border-radius: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  margin: 0;
  background: var(--speer-button1);
  color: var(--speer-button-color1);
}

.badge.small {
  padding: 4px 8px;
  margin-left: 4px;
}

.badge.badge-success {
  background: var(--speer-badge-success);
  color: var(--speer-badge-success-color);
}

.badge.badge-danger {
  background: #FDEEEB;
  color: #A02A2A;
}

.badge.badge-dark {
  background: var(--speer-accent-base);
  color: var(--speer-base1);
}

.badge.badge-bullet {
  padding-left: 24px;
}

.badge.badge-bullet::before {
  content: '';
  height: 6px;
  width: 6px;
  background: var(--speer-badge-success-color);
  border-radius: 100%;
  position: absolute;
  top: 7px;
  left: 12px;
  z-index: 1;
  animation: blink-animation 1s ease-in-out infinite;
  -webkit-animation: blink-animation 1s ease-in-out infinite;
}

.badge.badge-cursor {
  cursor: pointer;
}

.badge-rect {
  border-radius: 3px;
  padding: 4px 8px;
}

/* BREADCRUMBS */

.Breadcrumbs {
  margin-top: 24px;
  margin-bottom: 36px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
  position: relative;
}

.Breadcrumbs .button,
.Breadcrumbs button,
.Breadcrumbs .TableAction {
  position: absolute;
  right: 0;
  top: 0;
}

.Breadcrumbs-back {
  height: 44px;
  width: 44px;
  margin-right: 12px;
  background: var(--speer-base3);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.Breadcrumbs-path {
  height: 44px;
  background: var(--speer-base3);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
  padding-left: 24px;
  padding-right: 24px;
}

.Breadcrumbs-path span,
.Breadcrumbs-path a {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #6B6E97;
  margin-right: 6px;
}

.Breadcrumbs-path a.active {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: var(--speer-accent-primary);
}

/* ACTION & SPLIT CARDS */

.ActionCard,
.SplitCard {
  background: var(--speer-base2);
  border: 1px solid var(--speer-border1);
  box-sizing: border-box;
  border-radius: 6px;
  padding: 18px;
  padding-bottom: 24px;
}

.ActionCard.ActionCard--var(--speer-base1) {
  background: var(--speer-base1);
}

.ActionCard-file {
  z-index: 2;
}

.ActionCard-badge {
  position: absolute;
  top: 12px;
  right: 12px;
}

.ActionCard-title,
.SplitCard-title,
.Settings-grid-card-title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  color: var(--speer-accent-base);
  margin: 0;
  margin-bottom: 6px;
}

.ActionCard-body,
.SplitCard-body,
.Settings-grid-card-body {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 150%;
  color: #73799B;
  margin: 0;
  margin-bottom: 24px;
}

.ActionCard-body.no-action {
  margin-bottom: 6px;
}

.ActionCard-body a {
  font-weight: bold;
  color: var(--speer-accent-primary);
}

.ActionCard-actions {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row wrap;
}

.ActionCard-actions .ActionCard-action.link {
  margin-left: 12px;
}

.ActionCard-actions .Dropdown {
  margin-bottom: 0;
}

.ActionCard-action {
  background: var(--speer-button1);
  border-radius: 100px;
  color: var(--speer-button-color1);
  font-size: 15px;
  padding: 12px 24px;
  margin-right: 12px;
}

.SplitCard {
  grid-column-start: 1;
  grid-column-end: 3;
  padding: 0;
  display: grid;
  column-gap: 0;
  grid-template-columns: 1fr 1fr;
}

.ActionCard-double {
  grid-column-start: 1;
  grid-column-end: 3;
}

.SplitCard-left {
  padding: 18px;
}

.SplitCard-right {
  padding: 18px;
  background: var(--speer-base1);
  border-left: 1px solid var(--speer-border3);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

/* FILE CARD */

.FileCard {
  background: var(--speer-base3);
  border-radius: 6px;
  padding-left: 18px;
  padding-right: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  margin-bottom: 12px;
}

.FileCard-name {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  color: #6B6E97;  
}

.FileCard-name small {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
  color: #A6A7BB;
  margin-left: 6px;
}

.FileCard-action {
  background: var(--speer-accent-primary);
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;
}

/* CUSTOM SELECT */

.react-select__multi-value__remove {
  background: none !important;
  cursor: pointer;
}

.react-select__multi-value__remove svg {
  color: var(--speer-base1);
}

/* POPOVERS */

.popover-container {
  position: relative;
}

.popover-container.hidden {
  display: none;
}

.popover {
  position: absolute;
  top: 12px;
  right: 0;
  background: var(--speer-base2);
  box-shadow: 0px 3px 12px var(--speer-shadow);
  border-radius: 6px;
  padding: 24px 24px 6px 24px;
  min-width: 200px;
  z-index: 999;
}

.popover-container.extra-offset .popover {
  top: 24px;
}

.popover-menu {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column nowrap;
  z-index: 999;
}

.popover-action {
  margin-bottom: 18px;
}

.popover-action-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #959ABA;
  cursor: pointer;
  transition: all 150ms ease-in-out;
}

.popover-action:hover .popover-action-label,
.popover-action.active .popover-action-label {
  font-weight: bold;
  color: var(--speer-accent-primary);
}

.popover-action.danger:hover .popover-action-label {
  font-weight: bold;
  color: tomato;
}

/* OVERLAY */

.overlay-container {
  position: fixed;
  top: 0;
  left: 240px;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  min-width: calc(100vw - 240px);
  background: rgba(255, 255, 255, 0.9);
  z-index: 999;
}

.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.overlay-inner {
  background: var(--speer-base2);
  border: 1px solid var(--speer-border1);
  box-sizing: border-box;
  border-radius: 12px;
  padding: 36px 24px;
  max-width: 380px;
  margin-left: -240px;
  position: relative;
}

.overlay-header {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid var(--speer-border1);
}

.overlay-header.no-border {
  border-bottom: none;
}

.overlay-title {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: var(--speer-accent-base);
  margin: 0;
  margin-bottom: 18px;
}

.overlay-subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  color: var(--speer-accent-base);
  margin: 0;
}

.overlay-intro {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #717799;
  margin: 0;
}

.overlay-dismiss {
  position: absolute;
  top: 18px;
  right: 18px;
  color: var(--speer-button-color1);
  opacity: 0.2;
  cursor: pointer;
  transition: all 200ms ease-in-out;
}

.overlay-dismiss:hover {
  opacity: 1;
}

.overlay-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
}

.overlay-actions button,
.overlay-actions .button {
  margin-right: 12px;
}

.overlay-actions button:last-of-type,
.overlay-actions .button:last-of-type {
  margin-right: 0;
}

/* PROFILE STAR */

.profile-star {
  cursor: pointer;
  height: 100%;
  display: block;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.profile-star svg {
  fill: var(--speer-button1);
  stroke: var(--speer-button1);
  transition: all 200ms ease-out;
}

.profile-star:hover svg {
  fill: var(--speer-button1) !important;
  stroke: var(--speer-button-color1) !important;
}

.profile-star.active svg {
  fill: var(--speer-button-color1);
  stroke: var(--speer-button-color1);
}

/* TOGGLE SWITCH */

.toggle-switch {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
  background: var(--speer-base3);
  border-radius: 100px;
  padding: 2px;
  margin-right: 12px;
  cursor: pointer;
}

.toggle-option {
  padding: 9px 18px;
  border-radius: 100px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  background: none;
  color: #757692;
  margin: 0;
  transition: all 200ms ease-in-out;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.toggle-option:hover {
  color: #383874;
  -webkit-box-shadow:inset 0px 0px 0px 2px #383874;
  -moz-box-shadow:inset 0px 0px 0px 2px #383874;
  box-shadow:inset 0px 0px 0px 2px #383874;
}

.toggle-switch.button-sized .toggle-option {
  font-size: 14px;
  line-height: 16px;
  padding: 12px 24px;
}

.toggle-option.active {
  background: #383874;
  color: var(--speer-base1);
  font-weight: bold;
}

/* DROPDOWN */

.Dropdown {
  position: relative;
}

.Dropdown-margin-bottom {
  margin-bottom: 12px;
}

.Dropdown-trigger {
  background: none;
  border: 1px solid var(--speer-border1);
  box-sizing: border-box;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
  padding: 9px 18px;
  text-align: left;
}

.Dropdown.active .Dropdown-trigger {
  border: 1px solid var(--speer-accent-primary);
}

.Dropdown-trigger-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #A6A7BB;
  min-width: 130px;
}

.Dropdown.active .Dropdown-trigger-label {
  color: var(--speer-accent-primary);
  font-weight: bold;
}

.Dropdown-trigger svg {
  stroke-width: 1.5px;
  color: #A6A7BB;
  transition: all 200ms ease-in-out;
}

.Dropdown.active .Dropdown-trigger svg {
  transform: rotate(180deg);
  color: var(--speer-accent-primary);
}

.Dropdown-menu {
  position: absolute;
  top: 48px;
  right: 0;
  background: var(--speer-base2);
  box-shadow: 0px 3px 12px var(--speer-shadow);
  border-radius: 6px;
  padding: 12px;
  z-index: 99;
}

.Dropdown-menu.Dropdown-menu-top {
  top: auto;
  bottom: 48px;
}

.Dropdown-menu-item {
  display: block;
  min-width: 156px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #959ABA;
  padding: 12px;
  transition: all 200ms ease-in-out;
  cursor: pointer;
}

.Dropdown-menu-item:hover,
.Dropdown-menu-item.active {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: var(--speer-accent-primary);
}

/* BULLET ACTIONS */

.bullet-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-flow: row nowrap;
}

.bullet-action {
  margin-left: 12px;
}

.bullet-action svg {
  height: 20px;
  width: auto;
}

/* ANIMATIONS */

@keyframes fadeInUp {
  from { transform: translate3d(0,40px,0) }
  to { transform: translate3d(0,0,0); opacity: 1 }
}

@-webkit-keyframes fadeInUp {
  from { transform: translate3d(0,40px,0) }
  to { transform: translate3d(0,0,0); opacity: 1 }
}

@keyframes fadeIn {
  from { transform: translate3d(0,10px,0) }
  to { transform: translate3d(0,0,0); opacity: 1 }
}

@-webkit-keyframes fadeIn {
  from { transform: translate3d(0,10px,0) }
  to { transform: translate3d(0,0,0); opacity: 1 }
}

@keyframes fadeInDown {
  from { transform: translate3d(0,-20px,0) }
  to { transform: translate3d(0,0,0); opacity: 1 }
}

@-webkit-keyframes fadeInDown {
  from { transform: translate3d(0,-20px,0) }
  to { transform: translate3d(0,0,0); opacity: 1 }
}

@keyframes blink-animation {
  from { opacity: 0.3; }
  50% { opacity: 1; }
  to { opacity: 0.3; }
}

@-webkit-keyframes blink-animation {
  from { opacity: 0.3; }
  50% { opacity: 1; }
  to { opacity: 0.3; }
}

/* ANIMATION CLASSES */

.fade-in-up {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}

.fade-in-down {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  opacity: 0;
  animation-name: fadeInDown;
  -webkit-animation-name: fadeInDown;
}

.fade-in {
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  opacity: 0;
  animation-name: fadeIn;
  -webkit-animation-name: fadeIn;
}

/* ANIMATION DELAYS */

.delay-100 { animation-delay: 100ms; }
.delay-200 { animation-delay: 200ms; }
.delay-300 { animation-delay: 300ms; }
.delay-400 { animation-delay: 400ms; }
.delay-500 { animation-delay: 500ms; }
.delay-1000 { animation-delay: 1000ms; }
.delay-2000 { animation-delay: 2000ms; }

/* PLACEHOLDERS */

.placeholder-top-margin {
  margin-top: 60px;
}

.placeholder-block {
  background: var(--speer-base3);
  border-radius: 6px;
}

.placeholder-inner {
  padding: 48px 48px 54px;
  text-align: center;
}

.placeholder-title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  color: var(--speer-accent-base);
  margin: 0;
  margin-bottom: 12px;
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
}

.placeholder-body {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #73799B;
  margin-bottom: 36px;
  margin: 0;
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
}

.placeholder-inner .button {
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  margin-top: 24px;
}

.placeholder-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
  margin-left: -12px;
  margin-right: -12px;
}

.placeholder-inner .placeholder-actions .button {
  margin-right: 12px;
  margin-left: 12px;
}

/* APPOINTMENT BADGES */

.appointment-badge {
  background: var(--speer-base3);
  border-radius: 6px;
  padding: 12px 18px;
}

.appointment-badge-headline {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: var(--speer-accent-base);
  margin: 0;
  margin-bottom: 6px;
}

.appointment-badge-subline {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #73799B;
  margin: 0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.divider {
  border-top: 1px solid #eee;
  width: 100%;
  height: 1px;
  margin-top: 24px;
  margin-bottom: 48px;
}

#p4i-root .react-tel-input .flag-dropdown {
  width: 42px;
  background: var(--speer-base2);
  border: 1px solid #eee;
}

.emoji-wiggle {
  display: inline-block;
  animation: wiggle 1s ease 0.5s;
}

@keyframes wiggle {
  0% { transform: rotate(0deg); }
  10% { transform: rotate(-10deg); }
  20% { transform: rotate(0deg); }
  30% { transform: rotate(10deg); }
  40% { transform: rotate(0deg); }
  50% { transform: rotate(-10deg); }
  60% { transform: rotate(0deg); }
  70% { transform: rotate(10deg); }
  80% { transform: rotate(0deg); }
  90% { transform: rotate(-10deg); }
  100% { transform: rotate(0deg); }
}


/* Medium devices (landscape phones & tablets, 768px and up) */
@media screen and (max-width: 992px) {
  table.DataTable {
    display: block;
    overflow-x: auto;
    background: var(--speer-base3);
    padding: 24px;
    border-radius: 12px;
    margin-bottom: 36px;
    width: calc(100% - 48px);
  }

  table.DataTable tr td:last-of-type,
  table.DataTable tr th:last-of-type {
    padding-right: 24px;
  }

  .DataTable th,
  .DataTable td {
    min-width: 80px;
  }

  .DataTable th.sm,
  .DataTable td.sm {
    min-width: 100px;
  }

  .DataTable th.mid,
  .DataTable td.mid {
    min-width: 160px;
  }

  .DataTable th.mid_plus,
  .DataTable td.mid_plus {
    min-width: 210px;
  }

  .DataTable th.tiny,
  .DataTable td.tiny {
    min-width: 20px;
  }

  .Breadcrumbs {
    flex-flow: row wrap;
  }

  .Project-header {
    flex-flow: row wrap !important;
    margin-bottom: 36px;
  }

  .Project-header .Breadcrumbs {
    flex-flow: row nowrap;
    margin-bottom: 0;
  }

  .TableAction {
    position: relative;
    margin-top: 12px !important;
    width: 100%;
  }

  .TableAction input {
    width: 100%;
  }

  .Breadcrumbs {
    /* margin-bottom: 84px; */
    margin: 0;
  }

  .Breadcrumbs-path {
    overflow: hidden;
    var(--speer-base1)-space: nowrap;
  }

  .Breadcrumbs-path a.active,
  .Breadcrumbs-path span:last-of-type {
    display: none;
  }

  .Breadcrumbs .TableAction {
    width: 100%;
    margin-top: 60px !important;
  }

  .Search .ContentPanel-sidebar {
    margin-left: -24px !important;
    margin-top: -24px !important;
    padding: 24px;
  }

  .overlay-container {
    left: 0;
  }

  .overlay-inner {
    margin-left: initial;
  }

  .Search .TableControl {
    margin-top: 24px;
  }

  .warning {
    font-size: 12px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media screen and (max-width: 576px) {}