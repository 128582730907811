#speer-container {
  --speer-base1: white;
  --speer-base2: #f7f7f7;
  --speer-base3: #F8F9FE;

  --speer-text1: #6B6E97;
  --speer-text2: #717799;
  --speer-text3: #272F46;
  
  --speer-shadow: #dfe2f3;

  --speer-border1: #F1F1F3;
  --speer-border2: #F0F0F2;
  --speer-border3: #F5F5F6;

  --speer-button1: #EEF1FC;
  --speer-button-color1: #6877B2;

  --speer-accent-base: #203052;
  --speer-accent-primary: #3661EC;

  --speer-badge-success: #E0FAEF;
  --speer-badge-success-color: #326552;

  --speer-input-color: #282E3E;

  background: var(--speer-base1);
}

#speer-container.dark {
  --speer-base1: #121212;
  --speer-base2: rgb(10, 10, 10);
  --speer-base3: #0c0c0c;

  --speer-text1: #a5a9c0;
  --speer-text2: #9ba7cc;
  --speer-text3: #777996;

  --speer-shadow: #000;

  --speer-border1: #222;
  --speer-border2: #222;
  --speer-border3: #222;

  --speer-button1: #000;
  --speer-button-color1: #fff;

  --speer-accent-base: #f7f7f7;
  --speer-accent-primary: #36D6EC;

  --speer-badge-success: #7EFFC9;
  --speer-badge-success-color: #326552;

  --speer-input-color: #9ba7cc;

  background: var(--speer-base1);
}