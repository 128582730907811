.checkbox-field {
  margin-bottom: 24px;
}

.checkbox-field.checkbox-field-editable,
.checkbox-field.checkbox-field-narrow {
  margin-bottom: 12px;
}

.checkbox-field:last-of-type {
  margin-bottom: 0;
}

.checkbox-action-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.checkbox-icon-column {
  height: 24px;
  width: 24px;
  background: var(--speer-base1);
  border: 1px solid var(--speer-border1);
  margin-right: 12px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.checkbox-field-checked .checkbox-icon-column {
  border: 1px solid var(--speer-accent-primary);
}

.checkbox-field-checked .checkbox-icon-column svg {
  stroke: var(--speer-accent-primary);
  stroke-width: 2.5px;
  padding-left: 1px;
  padding-top: 1px;
}

.checkbox-label-column {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  color: #73799B;
}

.checkbox-field-editable .checkbox-label-column {
  width: 100%;
}

.checkbox-field-checked .checkbox-label-column {
  font-weight: bold;
  color: var(--speer-accent-primary);
}

.checkbox-info-row {
  padding-left: 36px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #73799B;
}

.editable-input {
  font-family: 'Roboto', sans-serif;
  background: var(--speer-base1);
  border: 1px solid var(--speer-border1);
  box-sizing: border-box;
  border-radius: 6px;
  box-shadow: none;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: var(--speer-input-color);
  padding: 12px 18px;
  width: 100%;
}