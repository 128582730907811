.settings-section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
}

.settings-section-title {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  color: var(--speer-accent-base);
}

.settings-subsection {
  background: var(--speer-base1);
  border-top: 1px solid var(--speer-border1);
  border-radius: 0px 0px 5px 5px;
  margin-top: 24px;
  margin-left: -18px;
  margin-right: -18px;
  margin-bottom: -18px;
  padding: 24px 18px 24px;
}

.adjustment-summary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
}

.adjustment-summary > div:nth-of-type(2) span {
  color: var(--speer-badge-success-color);
}

.adjustment-summary > div:first-of-type span {
  color: #A02A2A;
}