.MenuBar {
  height: 48px;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background: var(--speer-base2);
  z-index: 99999;
  border-bottom: 1px solid var(--speer-border1);
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
  display: none;
}

.MenuBar-left {
  padding-left: 24px;
}

.MenuBar-right {
  padding-right: 24px;
}

.MenuBar-right svg {
  cursor: pointer;
}

.MenuBar-left img {
  height: 18px;
  width: auto;
}

.MenuBar-main {
  background: var(--speer-base2);
  position: fixed;
  top: 49px;
  height: calc(100vh - 49px);
  width: 100%;
  left: 100vw;
  transition: all 200ms ease-in-out;
}

.MenuBar-main.active {
  left: 0
}

/* MOBILE */

@media only screen and (max-width: 1200px) {
  .MenuBar {
    display: flex;
  }

  .MenuBar .MenuBar-Sidebar {
    position: relative;
    padding-top: 24px;
  }

  .MenuBar .Sidebar-inner {
    position: relative;
    max-width: calc(100vw - 24px);
  }

  .MenuBar .Sidebar-section.Sidebar-section--narrow:first-of-type {
    display: none;
  }
}